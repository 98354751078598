<template>
  <span>
    <h5>{{ field.label }} Suche</h5>
    <b-form-group>
      <b-form-input
        v-model="searchInput"
        size="sm"
        placeholder="Suche"
        class="mb-2"
      />
    </b-form-group>
  </span>
</template>

<script>
import mergeQueries from './mergeQueries'
export default {
  name: 'FilterSearch',
  props: {
    field: {
      type: Object,
      required: true
    },
    opened: {
      type: Boolean,
      required: false
    }
  },
  data () {
    return {
      searchInput: ''
    }
  },
  computed: {
    label () {
      return (option) => {
        if (!option.label && this.field.formatter) {
          return this.field.formatter(option.value)
        } else if (!option.label) {
          return option.value
        } else {
          return option.label
        }
      }
    },
    query () {
      let query = { $and: [] }

      if (this.searchInput !== '') {
        const Q = { }
        if (this.field.filter.regex !== false) {
          Q[this.field.key] = { $regex: this.searchInput, $options: 'i' }
        } else {
          Q[this.field.key] = this.searchInput
        }
        query = mergeQueries(query, { $and: [Q] })
      }

      if (query.$and.length === 0) delete query.$and
      return query
    }
  },
  watch: {
    searchInput: {
      handler: 'updateFilterStatus'
    }
  },
  methods: {
    updateFilterStatus () {
      this.$emit('change', {
        isApplied: (this.searchInput !== ''),
        query: this.query
      })
    },
    deleteFilter () {
      this.searchInput = ''
    }
  }
}
</script>

<style lang="scss" scoped>

</style>
